import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Center,
  Square,
  Circle,
  HStack,
  Text,
  useColorMode,
  VStack,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactFooter from "../components/ContactFooter";
import Heroblock from "../components/Heroblock";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";
import GImage from "../DefinitionComponents/Utility/GImage";

const data = [
  {
    id: 1,
    text: "Einladung zur Hauptversammlung 2024",
    url: "../files/hv2024/240516Einladungstext_HV_2024.pdf",
  },
  {
    id: 2,
    text: "Testatsexemplar der tokentus investment AG für das Geschäftsjahr 2023",
    url: "../files/hv2024/tokentusJAzum2023Testatsexemplar.pdf",
  },

  {
    id: 4,
    text: "Aktuelle Satzung der tokentus investment AG",
    url: "../files/hv2024/230711UVZ_Nr_500_2023_nebst_Satzungsbescheinigung.pdf",
  },
  {
    id: 6,
    text: "Bericht des Aufsichtsrates 2023",
    url: "../files/hv2024/240524Bericht_des_AR_signed.pdf",
  },

  {
    id: 10,
    text: "Vollmacht und Weisungserteilung",
    url: "../files/hv2024/20240516_Indus_eForm.pdf",
  },
  {
    id: 11,
    text: "Anfahrtsbeschreibung Hilton Frankfurt Airport",
    url: "../files/hv2023/HiltonFraport.pdf",
  },
];

const Team = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("dark");
  });

  return (
    <>
      <Helmet>
        <title>tokentus | Hauptversammlung 2023</title>
      </Helmet>

      <HStack
        bgColor="white"
        justifyContent={"end"}
        minH="60vh"
        maxH={{ lg: "95vh", md: "auto" }}
      >
        <Container variant="layoutContainer" py={12} px={{ base: 0, lg: "8" }}>
          <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
            <Flex justifyContent="center" maxW="120px" mx="auto" mt={12}>
              <GImage mb={3} maxW="24px" src="signet_blue.svg" />
            </Flex>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              w={"100%"}
              color="brand.medgray"
              fontWeight="normal"
              fontSize="xl"
              textAlign={"center"}
            >
              Wir laden ein zur
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              w={"100%"}
              color="brand.blue"
              textAlign={"center"}
              fontWeight="normal"
              fontSize={{ base: "4xl", lg: "6xl" }}
            >
              Hauptversammlung 2024.
            </Text>
          </FadeInAnimation>

          <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
            <Heading
              w={"100%"}
              textAlign={"center"}
              fontSize={{ base: "xl", lg: "2xl" }}
              fontWeight="normal"
              mb="8"
              color="brand.medgray"
              maxW={{ base: "80%", lg: "55%" }}
              mx="auto"
            >
              Die Hauptversammlung 2024 der tokentus investment AG findet am
              09.07.2024 um 10:00 Uhr als Präsenzveranstaltung statt.
            </Heading>
          </FadeInAnimation>
        </Container>
      </HStack>

      <Box bg="brand.white" w={"100%"}>
        <Container
          variant="layoutContainer"
          px={{ base: 0, lg: 10 }}
          py={{ base: 0, lg: 0 }}
        >
          <Flex
            flexWrap="wrap"
            flexDirection="column"
            justifyContent={"space-between"}
          >
            {data.map((data) => (
              <Box w={{ base: "100%", lg: "100%" }} mb={0.5}>
                <Flex
                  wrap="wrap"
                  flexDirection={"row"}
                  alignContent="stretch"
                  alignItems={"stretch"}
                >
                  <HStack
                    h="100%"
                    visibility={{ base: "hidden", lg: "visible" }}
                    display={{ base: "none", md: "flex" }}
                    w="20%"
                    minH="220px"
                    p={0}
                    spacing={0}
                    bg={"brand.blue"}
                  >
                    <ImgMaskReveal
                      imgsrc={"newsthumb2.png"}
                      initScale={1.5}
                      delay={0}
                      duration={2}
                      threshold={0.4}
                      triggerOnce={true}
                    />
                  </HStack>
                  <VStack
                    w={{ base: "100%", lg: "80%" }}
                    px={10}
                    py={10}
                    alignItems={"flex-center"}
                    justifyContent="center"
                    bg={"brand.lightgray"}
                  >
                    <Heading
                      w={{ base: "100%", lg: "70%" }}
                      fontSize="xl"
                      fontWeight="normal"
                      lineHeight={"shorter"}
                      color="brand.meddarkgray"
                    >
                      {data.text}
                    </Heading>
                    <Link href={data.url}>
                      <Button
                        color="white"
                        size={"lg"}
                        fontWeight={"normal"}
                        px={6}
                        backgroundColor="brand.meddarkgray"
                        leftIcon={
                          <GImage
                            w="14px"
                            h="14px"
                            src="download-document.svg"
                          />
                        }
                      >
                        PDF Download
                      </Button>
                    </Link>
                  </VStack>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Container>

        <Container variant={"layoutContainer"} mb={0.5}>
          <ContactFooter
            title="Any questions?"
            subtitle={" This is a special time. For special people."}
            name="Sarah Payne, COO"
            email="sarah.payne@tokentus.com"
            phone="+49 177 24 21 383"
          />
        </Container>
      </Box>
    </>
  );
};

export default Team;
